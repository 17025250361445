import React from 'react';
import { Paper, Box, Typography, Link as MuiLink } from '@mui/material';
import { observer } from 'mobx-react';

const MessagesSection = observer(({ messages }) => {
  return (
    <Paper 
      elevation={0}
      sx={{ 
        mb: 3,
        borderRadius: '12px',
        bgcolor: '#FFFFFF',
        border: '1px solid #E5E7EB',
        overflow: 'hidden'
      }}
    >
      <Typography 
        variant="h6" 
        sx={{ 
          p: 3,
          pb: 2,
          fontSize: '20px',
          fontWeight: 600,
          color: '#111827'
        }}
      >
        Messages
      </Typography>

      {messages.map((msg) => (
        <Box 
          key={msg.id}
          sx={{
            p: 3,
            pt: 2,
            pb: 2,
            '&:hover': {
              bgcolor: '#F9FAFB'
            }
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box 
              component="img"
              src="/api/placeholder/40/40"
              alt={msg.user}
              sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                objectFit: 'cover'
              }}
            />
            <Box sx={{ flex: 1 }}>
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 0.5
                }}
              >
                <Typography 
                  sx={{ 
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#111827'
                  }}
                >
                  {msg.user}
                </Typography>
                <Typography 
                  sx={{ 
                    fontSize: '14px',
                    color: '#6B7280'
                  }}
                >
                  {msg.timestamp}
                </Typography>
              </Box>
              <Typography 
                sx={{ 
                  fontSize: '14px',
                  color: '#374151',
                  lineHeight: 1.5
                }}
              >
                {msg.content}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
      
      <Box 
        sx={{ 
          p: 3,
          pt: 2
        }}
      >
        <MuiLink
          href="#"
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            color: '#2563EB',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          View All
        </MuiLink>
      </Box>
    </Paper>
  );
});

export default MessagesSection;