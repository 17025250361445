import { makeObservable, observable, action } from 'mobx';

// Helper functions to generate random data
const generateRandomId = () => `RFQ${Math.random().toString(36).substring(2, 8).toUpperCase()}`;

const generateRandomDate = (start = new Date(2024, 4, 15), end = new Date(2024, 6, 15)) => {
  const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
};

const generateRandomProcessingTime = () => {
  return `${Math.floor(Math.random() * 5 + 1)}d`;
};

const users = ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'];
const suppliers = ['Supplier 1', 'Supplier 2', 'Supplier 3', 'Supplier 4', 'Supplier 5'];
const statuses = ['Ready for Decision', 'In Progress', 'Completed', 'Pending Review', 'Awaiting Response'];

const generateRandomPartnership = (index) => {
  const totalSuppliers = Math.floor(Math.random() * 2) + 2; // 2-3 suppliers
  const totalUsers = Math.floor(Math.random() * 2) + 2; // 2-3 users
  const createdDate = generateRandomDate();
  
  return {
    id: generateRandomId(),
    name: `Example Request ${index + 1}`,
    users: Array.from({ length: totalUsers }, () => users[Math.floor(Math.random() * users.length)]),
    created: createdDate,
    expires: generateRandomDate(new Date(createdDate), new Date(2024, 6, 15)),
    processingTime: generateRandomProcessingTime(),
    suppliers: Array.from({ length: totalSuppliers }, () => suppliers[Math.floor(Math.random() * suppliers.length)]),
    responses: { 
      total: totalSuppliers,
      current: Math.floor(Math.random() * (totalSuppliers + 1))
    },
    parts: {
      total: totalSuppliers,
      current: Math.floor(Math.random() * (totalSuppliers + 1))
    },
    status: statuses[Math.floor(Math.random() * statuses.length)]
  };
};

class PartnershipStore {
  partnerships = Array.from({ length: 123 }, (_, index) => generateRandomPartnership(index));
  
  drafts = Array.from({ length: 16 }, (_, index) => ({
    id: `Draft-${index + 1}`,
    name: 'Draft Request'
  }));

  constructor() {
    makeObservable(this, {
      partnerships: observable,
      drafts: observable,
      setPartnerships: action
    });
  }

  setPartnerships(partnerships) {
    this.partnerships = partnerships;
  }
}

const partnershipStore = new PartnershipStore();
export default partnershipStore;