import React, { useState } from 'react';
import { 
  Paper, 
  Box, 
  Typography, 
  IconButton,
  TextField,
  Menu,
  MenuItem
} from '@mui/material';
import { Plus, Pencil, Check, X } from 'lucide-react';
import { observer } from 'mobx-react';
import worklistStore from '../store/worklistStore';

// Move these to separate components to fix the runtime errors
const EditableContent = ({ 
  isEditing, 
  value, 
  tempValue, 
  onEdit, 
  onSave, 
  onCancel 
}) => {
  if (isEditing) {
    return (
      <>
        <TextField
          value={tempValue}
          onChange={(e) => onEdit(e.target.value)}
          variant="standard"
          size="small"
          autoFocus
          sx={{ flex: 1 }}
        />
        <IconButton size="small" onClick={onSave} sx={{ p: 0.5 }}>
          <Check size={16} />
        </IconButton>
        <IconButton size="small" onClick={onCancel} sx={{ p: 0.5 }}>
          <X size={16} />
        </IconButton>
      </>
    );
  }

  return (
    <>
      <Typography 
        variant="body1" 
        sx={{ 
          color: '#000',
          fontSize: '1rem',
          fontWeight: 400
        }}
      >
        {value}
      </Typography>
      <IconButton 
        size="small" 
        onClick={() => onEdit(value)}
        sx={{ p: 0.5 }}
      >
        <Pencil size={16} />
      </IconButton>
    </>
  );
};

const SectionTitle = ({ title }) => (
  <Typography 
    variant="h6" 
    sx={{ 
      mb: 1,
      fontWeight: 800,
      fontSize: '1.1rem',
      color: '#000'
    }}
  >
    {title}
  </Typography>
);

const WorklistSidebar = observer(({ request }) => {
  const [editingField, setEditingField] = useState(null);
  const [tempValue, setTempValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  
  const stages = ['Intend to Bid', 'Quoted', 'Submitted', 'Awarded'];

  const handleStartEdit = (field, value) => {
    setEditingField(field);
    setTempValue(value);
  };

  const handleSave = (field) => {
    worklistStore.updateField(field, tempValue);
    setEditingField(null);
    setTempValue('');
  };

  const handleCancel = () => {
    setEditingField(null);
    setTempValue('');
  };

  const handleStageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStageClose = () => {
    setAnchorEl(null);
  };

  const handleStageSelect = (stage) => {
    worklistStore.updateStage(stage);
    handleStageClose();
  };

  const renderEditableSection = (title, field, value) => (
    <Box sx={{ mb: 3 }}>
      <SectionTitle title={title} />
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <EditableContent
          isEditing={editingField === field}
          value={value}
          tempValue={tempValue}
          onEdit={(value) => handleStartEdit(field, value)}
          onSave={() => handleSave(field)}
          onCancel={handleCancel}
        />
      </Box>
    </Box>
  );

  const renderStageSection = () => (
    <Box sx={{ mb: 3 }}>
      <SectionTitle title="Stage" />
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Box 
          sx={{ 
            display: 'inline-block',
            color: '#2E7D32',
            bgcolor: '#E8F5E9',
            px: 2,
            py: 1,
            borderRadius: '8px',
            fontSize: '0.9rem'
          }}
        >
          {request?.stage}
        </Box>
        <IconButton 
          size="small" 
          onClick={handleStageClick}
          sx={{ p: 0.5 }}
        >
          <Pencil size={16} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleStageClose}
        >
          {stages.map((stage) => (
            <MenuItem 
              key={stage} 
              onClick={() => handleStageSelect(stage)}
              selected={stage === request?.stage}
            >
              {stage}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );

  const renderStaticSection = (title, value) => (
    <Box sx={{ mb: 3 }}>
      <SectionTitle title={title} />
      <Typography variant="body1" sx={{ color: '#000' }}>
        {value}
      </Typography>
    </Box>
  );

  return (
    <Paper 
      sx={{ 
        width: 300, 
        p: 3,
        borderRadius: '12px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
      }}
    >
      {renderEditableSection("Workspace Name", "workspaceName", request?.workspaceName)}
      {renderEditableSection("Opportunity", "reference", request?.reference)}
      
      <Box sx={{ mb: 3 }}>
        <SectionTitle title="Participants" />
        <IconButton 
          size="small" 
          sx={{ 
            border: '1px solid #1976d2',
            borderRadius: '8px',
            p: 1,
            color: '#1976d2'
          }}
        >
          <Plus size={20} />
        </IconButton>
      </Box>

      {renderStageSection()}
      {renderStaticSection("Created At", request?.createdAt)}
      {renderStaticSection("Last Modified", request?.lastModified)}
    </Paper>
  );
});

export default WorklistSidebar;