import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PartnershipTable from './components/PartnershipTable';
import DraftsDrawer from './components/DraftsDrawer';
import partnershipStore from './store/partnershipStore';

const Partnership = observer(() => {
  const [isDraftsOpen, setIsDraftsOpen] = useState(false);

  const handleDraftsOpen = () => {
    setIsDraftsOpen(true);
  };

  const handleDraftsClose = () => {
    setIsDraftsOpen(false);
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/worklist')}  // Add this onClick handler
          sx={{
            backgroundColor: '#00A3FF',
            '&:hover': {
              backgroundColor: '#0090E0',
            },
            textTransform: 'none',
            fontWeight: 'bold',
            gap: 1,
            px: 3,
            py: 1,
          }}
        >
          Create A Request
        </Button>
        <Button
          variant="outlined"
          onClick={handleDraftsOpen}
          startIcon={<DescriptionOutlinedIcon />}
          sx={{ 
            color: '#000', 
            borderColor: '#D9D9D9',
            backgroundColor: '#F5F5F5',
            '&:hover': {
              backgroundColor: '#E0E0E0',
              borderColor: '#A6A6A6',
            },
            textTransform: 'none',
            fontWeight: 'bold',
            gap: 1,
            px: 3,
            py: 1,
          }}
        >
          Drafts ({partnershipStore.drafts.length})
        </Button>
      </Box>
      
      <PartnershipTable />

      <DraftsDrawer
        open={isDraftsOpen}
        onClose={handleDraftsClose}
        drafts={partnershipStore.drafts}
      />
    </Box>
  );
});

export default Partnership;