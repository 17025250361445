import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { Paper, Box, Typography, IconButton, Tooltip } from '@mui/material';
import { Plus, Upload, FileText, Download, Trash2 } from 'lucide-react';
import worklistStore from '../store/worklistStore';

const QuotesSection = observer(() => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  
  const isValidFileType = (file) => {
    const validTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    return validTypes.includes(file.type);
  };

  const handleFiles = (files) => {
    Array.from(files).forEach(file => {
      if (isValidFileType(file)) {
        worklistStore.addQuote(file);
      }
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = (e) => {
    handleFiles(e.target.files);
    e.target.value = '';
  };

  const handleQuoteClick = (quote) => {
    // In real implementation, this would open the file preview or download
    console.log('Opening quote:', quote.name);
  };

  const handleQuoteDownload = (e, quote) => {
    e.stopPropagation(); // Prevent quote click event
    // In real implementation, this would trigger the download
    console.log('Downloading quote:', quote.name);
  };

  const handleQuoteDelete = (e, quote) => {
    e.stopPropagation(); // Prevent quote click event
    worklistStore.removeQuote(quote.id);
  };

  return (
    <Paper 
      elevation={0}
      sx={{ 
        mb: 3,
        p: 3,
        borderRadius: '12px',
        bgcolor: '#FFFFFF',
        border: '1px solid #E5E7EB',
        position: 'relative'
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {/* Drag overlay */}
      {isDragging && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(37, 99, 235, 0.05)',
            border: '2px dashed #2563EB',
            borderRadius: '12px',
            zIndex: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 1
          }}
        >
          <Upload size={24} color="#2563EB" />
          <Typography sx={{ color: '#2563EB', fontWeight: 500 }}>
            Drop files to upload
          </Typography>
        </Box>
      )}

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2 
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: '20px',
            fontWeight: 600,
            color: '#111827'
          }}
        >
          Quotes
        </Typography>
        <IconButton 
          onClick={handleClick}
          sx={{
            width: '32px',
            height: '32px',
            border: '1px solid #E5E7EB',
            borderRadius: '8px',
            p: '6px'
          }}
        >
          <Plus size={20} />
        </IconButton>
      </Box>

      <input
        ref={fileInputRef}
        type="file"
        onChange={handleChange}
        accept=".pdf,.docx"
        style={{ display: 'none' }}
        multiple
      />

      {worklistStore.quotes.length === 0 ? (
        <Box
          onClick={handleClick}
          sx={{
            border: '1px dashed #E5E7EB',
            borderRadius: '8px',
            p: 4,
            textAlign: 'center',
            cursor: 'pointer',
            bgcolor: '#F9FAFB',
            '&:hover': {
              bgcolor: '#F3F4F6'
            }
          }}
        >
          <Upload 
            size={24} 
            strokeWidth={1.5}
            className="text-gray-400 mx-auto mb-2"
          />
          <Typography 
            sx={{ 
              color: '#6B7280',
              fontSize: '14px',
              fontWeight: 500
            }}
          >
            Choose a PDF or DOCX file or drop it here
          </Typography>
        </Box>
      ) : (
        <Box sx={{ position: 'relative' }}>
          {worklistStore.quotes.map((quote) => (
            <Box
              key={quote.id}
              onClick={() => handleQuoteClick(quote)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                borderRadius: '8px',
                bgcolor: '#F9FAFB',
                mb: 1,
                cursor: 'pointer',
                transition: 'all 0.2s',
                '&:hover': {
                  bgcolor: '#F3F4F6'
                }
              }}
            >
              <FileText size={20} className="text-gray-400 mr-2" />
              <Typography 
                sx={{ 
                  flex: 1, 
                  fontSize: '14px',
                  color: '#374151',
                  fontWeight: 500
                }}
              >
                {quote.name}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Tooltip title="Download">
                  <IconButton
                    size="small"
                    onClick={(e) => handleQuoteDownload(e, quote)}
                    sx={{ color: '#6B7280' }}
                  >
                    <Download size={16} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    onClick={(e) => handleQuoteDelete(e, quote)}
                    sx={{ color: '#6B7280' }}
                  >
                    <Trash2 size={16} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Paper>
  );
});

export default QuotesSection;