import React from 'react';
import { Drawer, List, ListItem, ListItemText, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DraftsDrawer = ({ open, onClose, drafts }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 320 }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Drafts</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {drafts.map((draft) => (
            <ListItem key={draft.id} button>
              <ListItemText 
                primary={draft.name}
                secondary={draft.id}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default DraftsDrawer;