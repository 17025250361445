import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  Box,
  Typography,
  Avatar,
  AvatarGroup,
  Chip,
  Paper,
  TablePagination,
} from '@mui/material';
import { ArrowUpNarrowWide, ArrowDownNarrowWide } from 'lucide-react';
import partnershipStore from '../store/partnershipStore';

const ProgressCircle = ({ value, total }) => {
  const percentage = (value / total) * 100;
  const strokeWidth = 2;
  const size = 45;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
      <svg width={size} height={size}>
        <circle
          stroke="#E0E0E0"
          fill="none"
          strokeWidth={strokeWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke="#00A3FF"
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          r={radius}
          cx={size / 2}
          cy={size / 2}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: offset,
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
          }}
        />
      </svg>
      <Typography
        variant="caption"
        component="div"
        color="text.secondary"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '0.7rem',
        }}
      >
        {`${value}/${total}`}
      </Typography>
    </Box>
  );
};

const PartnershipTable = observer(() => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });

  const headerColour = '#565';

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  // Sorting function
  const sortData = (data) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle nested objects and special cases
      if (sortConfig.key === 'responses') {
        aValue = a.responses.current / a.responses.total;
        bValue = b.responses.current / b.responses.total;
      } else if (sortConfig.key === 'parts') {
        aValue = a.parts.current / a.parts.total;
        bValue = b.parts.current / b.parts.total;
      } else if (sortConfig.key === 'users') {
        aValue = a.users.length;
        bValue = b.users.length;
      } else if (sortConfig.key === 'suppliers') {
        aValue = a.suppliers.length;
        bValue = b.suppliers.length;
      } else if (sortConfig.key === 'created' || sortConfig.key === 'expires') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      } else if (sortConfig.key === 'processingTime') {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const SortIndicator = ({ column }) => {
    if (sortConfig.key !== column) return null;
    
    return sortConfig.direction === 'asc' 
      ? <ArrowUpNarrowWide size={16} className="ml-1" /> 
      : <ArrowDownNarrowWide size={16} className="ml-1" />;
  };

  const HeaderCell = ({ text, column, flex = 1, align = 'left' }) => (
    <Box 
      sx={{ 
        flex, 
        display: 'flex',
        alignItems: 'center',
        justifyContent: align === 'center' ? 'center' : 'flex-start',
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.8
        },
        borderRight: '1px solid #D0D0D0',
        '&:last-child': {
          borderRight: 'none'
        }
      }}
      onClick={() => handleSort(column)}
    >
      <Typography sx={{ 
        fontWeight: 'bold', 
        color: headerColour,
        display: 'flex',
        alignItems: 'center'
      }}>
        {text}
        <SortIndicator column={column} />
      </Typography>
    </Box>
  );

  // Get sorted and paginated data
  const sortedData = sortData(partnershipStore.partnerships);
  const displayedPartnerships = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        backgroundColor: '#F2F2F2', 
        p: 2, 
        borderRadius: '8px 8px 0 0'
      }}>
        <HeaderCell text="Name" column="name" flex={3} />
        <HeaderCell text="Users" column="users" align="center" />
        <HeaderCell text="Created" column="created" align="center" />
        <HeaderCell text="Expires" column="expires" align="center" />
        <HeaderCell text="Processing Time" column="processingTime" align="center" />
        <HeaderCell text="Suppliers" column="suppliers" align="center" />
        <HeaderCell text="Responses" column="responses" align="center" />
        <HeaderCell text="Parts" column="parts" align="center" />
        <HeaderCell text="Status" column="status" align="center" />
      </Box>

      {displayedPartnerships.map((row, index) => (
        <Paper
          key={row.id}
          elevation={0}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/worklist/${row.id}`)}  // Add this onClick handler
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            mb: 1,
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            '&:hover': {
              backgroundColor: '#F5F5F5',
            },
          }}
        >
          <Box sx={{ flex: 3 }}>
            <Typography variant="body2" sx={{ fontWeight: '600', color: '#333' }}>
              {row.id} - {row.name}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: '0.75rem' } }}>
              {row.users.map((user, i) => (
                <Avatar key={i} sx={{ bgcolor: '#E0E0E0', color: '#666' }}>{user.charAt(0)}</Avatar>
              ))}
            </AvatarGroup>
          </Box>
          <Typography sx={{ flex: 1, color: '#666', textAlign: 'center' }}>{row.created}</Typography>
          <Typography sx={{ flex: 1, color: '#666', textAlign: 'center' }}>{row.expires}</Typography>
          <Typography sx={{ flex: 1, color: '#666', textAlign: 'center' }}>{row.processingTime}</Typography>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: '0.75rem' } }}>
              {row.suppliers.map((supplier, i) => (
                <Avatar key={i} sx={{ bgcolor: '#E0E0E0', color: '#666' }}>{supplier.charAt(0)}</Avatar>
              ))}
            </AvatarGroup>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <ProgressCircle value={row.responses.current} total={row.responses.total} />
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <ProgressCircle value={row.parts.current} total={row.parts.total} />
          </Box>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Chip
              label={row.status}
              size="small"
              sx={{
                backgroundColor: 
                  row.status === 'Ready for Decision' ? '#E8F5E9' :
                  row.status === 'In Progress' ? '#FFF8E1' :
                  row.status === 'Completed' ? '#E3F2FD' :
                  row.status === 'Pending Review' ? '#FFF3E0' :
                  '#FFF9C4',
                color: 
                  row.status === 'Ready for Decision' ? '#2E7D32' :
                  row.status === 'In Progress' ? '#F57C00' :
                  row.status === 'Completed' ? '#1565C0' :
                  row.status === 'Pending Review' ? '#EF6C00' :
                  '#AFB42B',
                fontWeight: 'bold',
                fontSize: '0.75rem'
              }}
            />
          </Box>
        </Paper>
      ))}

<Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mt: 2,
          px: 2,
          py: 1,
          backgroundColor: '#F2F2F2',
          borderRadius: '8px',
        }}
      >
        <TablePagination
          component="div"
          count={partnershipStore.partnerships.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
          sx={{
            '.MuiTablePagination-select': {
              backgroundColor: '#FFF',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#F5F5F5',
              },
            },
            '.MuiTablePagination-selectIcon': {
              color: '#666',
            },
            '.MuiTablePagination-displayedRows': {
              color: '#666',
              fontWeight: '500',
            },
            '.MuiTablePagination-actions': {
              '& button': {
                color: '#666',
                '&:hover': {
                  backgroundColor: '#E0E0E0',
                },
                '&.Mui-disabled': {
                  color: '#BBB',
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
});

export default PartnershipTable;