import React from 'react';
import { Container, Card, CardContent, Typography, Box, Chip } from '@mui/material';
import { Construction } from '@mui/icons-material';

const UnderConstruction = ({ pageName }) => {
  return (
    <Container>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '80vh' 
      }}>
        <Card sx={{ 
          maxWidth: 800, 
          width: '100%',
          backgroundColor: '#FEFEFE',
          boxShadow: 'none',
          border: '1px solid #D9D9D9'
        }}>
          <CardContent sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            padding: 4,
            position: 'relative'
          }}>
            {/* Status Chip */}
            <Chip
              icon={<Construction />}
              label="UNDER CONSTRUCTION"
              sx={{
                position: 'absolute',
                top: 8,
                backgroundColor: '#FEF3C7', // Light yellow background
                color: '#D97706', // Amber text
                fontWeight: 'bold',
                border: '1px solid #FCD34D',
                '& .MuiChip-icon': {
                  color: '#D97706'
                }
              }}
            />

            {/* Page Name */}
            <Typography variant="h4" sx={{ 
              fontWeight: 'bold',
              mt: 3,
              mb: 1,
              textAlign: 'center',
              color: '#1976d2'
            }}>
              {pageName}
            </Typography>

            {/* Divider */}
            <Box 
              sx={{ 
                width: '60px',
                height: '4px',
                backgroundColor: '#1976d2',
                margin: '16px 0',
                borderRadius: '2px'
              }} 
            />

            {/* Construction Icon */}
            <Construction sx={{ 
              fontSize: 64, 
              color: '#D97706',
              margin: '16px 0' 
            }} />

            {/* Message */}
            <Typography 
              variant="h6" 
              sx={{ 
                textAlign: 'center',
                color: '#6B7280',
                fontWeight: 'normal'
              }}
            >
              This section is currently being developed.
            </Typography>
            
            <Typography 
              variant="body1" 
              color="text.secondary" 
              sx={{ 
                textAlign: 'center',
                mt: 2
              }}
            >
              We're working hard to bring you new features.
              Please check back later.
            </Typography>

            {/* Timeline Message */}
            <Chip
              label="Coming Soon"
              sx={{
                mt: 3,
                backgroundColor: '#E3F2FD',
                color: '#1976d2',
                fontWeight: 'bold'
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default UnderConstruction;