import React, { useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { 
  Paper, 
  Box, 
  Typography, 
  TextField,
  IconButton, 
  Button,
  Tooltip
} from '@mui/material';
import { Paperclip, X, Image } from 'lucide-react';
import worklistStore from '../store/worklistStore';

const MessageInput = observer(({ message, onChange, onSend, onDiscard }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const fileInputRef = useRef(null);
  const saveTimeoutRef = useRef(null);

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files || []);
    files.forEach(file => worklistStore.addMessageAttachment(file));
    event.target.value = '';
  };

  const handleChange = useCallback((event) => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    onChange(event);
    setSaveStatus('Changes Saved');

    saveTimeoutRef.current = setTimeout(() => {
      setSaveStatus('');
    }, 2000);
  }, [onChange]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    files.forEach(file => worklistStore.addMessageAttachment(file));
  };

  const handleSend = () => {
    worklistStore.clearMessageAttachments();
    onSend();
    setSaveStatus('');
  };

  const handleDiscard = () => {
    worklistStore.clearMessageAttachments();
    onDiscard();
    setSaveStatus('');
  };

  React.useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Paper 
      elevation={0}
      sx={{ 
        borderRadius: '12px',
        border: '1px solid #E5E7EB',
        bgcolor: '#FFFFFF',
        overflow: 'hidden'
      }}
    >
      <Box 
        sx={{ p: 3 }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 2,
            fontSize: '20px',
            fontWeight: 600,
            color: '#111827'
          }}
        >
          Write a Message
        </Typography>

        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Begin writing your message here"
          value={message}
          onChange={handleChange}
          variant="outlined"
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              backgroundColor: isDragging ? '#F3F4F6' : '#F9FAFB',
              transition: 'background-color 0.2s',
              '& fieldset': {
                borderColor: isDragging ? '#D1D5DB' : '#E5E7EB',
              },
              '&:hover fieldset': {
                borderColor: '#D1D5DB',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#2563EB',
              },
            },
            '& .MuiOutlinedInput-input': {
              fontSize: '14px',
              color: '#374151',
            },
          }}
        />

        {worklistStore.messageAttachments.length > 0 && (
          <Box sx={{ mb: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {worklistStore.messageAttachments.map((att) => (
              <Box
                key={att.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  p: 1,
                  pl: 2,
                  borderRadius: '6px',
                  bgcolor: '#F3F4F6',
                  fontSize: '14px',
                  color: '#374151'
                }}
              >
                {att.preview ? (
                  <Image size={16} />
                ) : (
                  <Paperclip size={16} />
                )}
                {att.name}
                <IconButton 
                  size="small"
                  onClick={() => worklistStore.removeMessageAttachment(att.id)}
                  sx={{ color: '#6B7280' }}
                >
                  <X size={14} />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          {/* Rest of the buttons section remains the same */}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDiscard}
              sx={{ 
                textTransform: 'none',
                borderRadius: '6px',
                fontSize: '14px',
                fontWeight: 500,
                px: 3,
                bgcolor: '#FEE2E2',
                borderColor: '#FCA5A5',
                color: '#DC2626',
                '&:hover': {
                  bgcolor: '#FEE2E2',
                  borderColor: '#EF4444',
                }
              }}
            >
              Discard
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              multiple
            />
            <Tooltip title="Attach files">
              <IconButton
                onClick={handleAttachmentClick}
                sx={{ 
                  border: '1px solid #E5E7EB',
                  borderRadius: '6px',
                  p: '8px',
                  color: '#6B7280'
                }}
              >
                <Paperclip size={20} />
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {saveStatus && (
              <Typography 
                sx={{ 
                  color: '#6B7280',
                  fontSize: '14px'
                }}
              >
                {saveStatus}
              </Typography>
            )}
            <Button
              variant="contained"
              onClick={handleSend}
              sx={{ 
                textTransform: 'none',
                borderRadius: '6px',
                bgcolor: '#4B5563',
                fontSize: '14px',
                fontWeight: 500,
                px: 3,
                '&:hover': {
                  bgcolor: '#374151'
                }
              }}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
});

export default MessageInput;