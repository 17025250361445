import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Breadcrumbs, Typography, Link as MuiLink } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import QuotesSection from './components/QuotesSection';
import MessagesSection from './components/MessagesSection';
import MessageInput from './components/MessageInput';
import WorklistSidebar from './components/WorklistSidebar';
import worklistStore from './store/worklistStore';

const Worklist = observer(() => {
  const { requestId } = useParams();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (requestId) {
      worklistStore.fetchRequest(requestId);
    } else {
      worklistStore.initializeNewRequest();
    }
  }, [requestId]);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSend = () => {
    // Handle sending message
    setMessage('');
  };

  const handleDiscard = () => {
    setMessage('');
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs separator={<ChevronRight size={16} />}>
          <MuiLink component={Link} to="/worklist" color="inherit">
            Worklist
          </MuiLink>
          <Typography color="text.primary">
            {requestId || 'New Request'}
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box sx={{ flex: 1 }}>
          <QuotesSection />
          <MessagesSection messages={worklistStore.messages} />
          <MessageInput
            message={message}
            onChange={handleMessageChange}
            onSend={handleSend}
            onDiscard={handleDiscard}
          />
        </Box>
        <WorklistSidebar request={worklistStore.request} />
      </Box>
    </Box>
  );
});

export default Worklist;