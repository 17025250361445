import { makeAutoObservable } from 'mobx';

class WorklistStore {
  request = null;
  messages = [];
  quotes = [];
  participants = [];
  messageAttachments = []; // Initialize the array


  constructor() {
    makeAutoObservable(this);
  }

  initializeNewRequest() {
    const timestamp = new Date().toLocaleString();
    this.request = {
      id: null,
      workspaceName: 'New Request',
      reference: 'New Opportunity',
      stage: 'Intend to Bid',
      createdAt: timestamp,
      lastModified: timestamp,
    };
    // Don't reset quotes here to persist them during sidebar interactions
    this.messages = [];
    this.participants = [];
  }

  addMessageAttachment(file) {
    const attachment = {
      id: Date.now() + Math.random(),
      name: file.name,
      size: file.size,
      type: file.type,
      preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
      file
    };
    
    this.messageAttachments.push(attachment);
    if (this.request) {
      this.request.lastModified = new Date().toLocaleString();
    }
  }

  removeMessageAttachment(id) {
    const attachment = this.messageAttachments.find(att => att.id === id);
    if (attachment?.preview) {
      URL.revokeObjectURL(attachment.preview);
    }
    
    this.messageAttachments = this.messageAttachments.filter(att => att.id !== id);
    if (this.request) {
      this.request.lastModified = new Date().toLocaleString();
    }
  }

  clearMessageAttachments() {
    this.messageAttachments.forEach(att => {
      if (att.preview) {
        URL.revokeObjectURL(att.preview);
      }
    });
    this.messageAttachments = [];
  }
  
  async fetchRequest(id) {
    try {
      // In a real implementation, this would be an API call
      const referenceId = `RFQXXXXXXX`;
      const request = {
        id,
        workspaceName: 'ExampleOpp',
        reference: `ExampleOpp #${referenceId}`,
        stage: 'Intend to Bid',
        createdAt: 'MM/YY/DDDD XX:XX AM',
        lastModified: 'MM/YY/DDDD XX:XX PM',
      };
      
      this.request = request;
      this.messages = [
        {
          id: 1,
          user: 'Test User 1',
          content: 'This is a short preview of the message example text here...',
          timestamp: '30m ago'
        }
      ];
      
      // Only initialize quotes if they haven't been loaded yet
      if (this.quotes.length === 0) {
        // In a real implementation, would fetch quotes from backend
        this.quotes = [];
      }
      if (this.messageAttachments.length === 0) {
        this.messageAttachments = [];
      }
    } catch (error) {
      console.error('Error fetching request:', error);
    }
  }



  addQuote(file) {
    const extension = file.name.split('.').pop().toLowerCase();
    
    if (!(extension === 'pdf' || extension === 'docx')) {
      console.error('Invalid file type');
      return;
    }

    // Create a URL for the file
    const fileUrl = URL.createObjectURL(file);
    
    const quote = {
      id: Date.now(),
      name: file.name,
      size: file.size,
      type: file.type,
      extension,
      url: fileUrl, // Store URL for preview/download
      uploadedAt: new Date().toISOString(),
      status: 'uploaded'
    };
    
    this.quotes.push(quote);
    this.request.lastModified = new Date().toLocaleString();
  }

  removeQuote(quoteId) {
    const quote = this.quotes.find(q => q.id === quoteId);
    if (quote?.url) {
      URL.revokeObjectURL(quote.url); // Clean up the object URL
    }
    
    this.quotes = this.quotes.filter(quote => quote.id !== quoteId);
    this.request.lastModified = new Date().toLocaleString();
  }

  updateField(field, value) {
    if (this.request && ['workspaceName', 'reference'].includes(field)) {
      this.request[field] = value;
      this.request.lastModified = new Date().toLocaleString();
      // In a real implementation, this would make an API call to update the backend
    }
  }

  updateStage(stage) {
    if (this.request) {
      const validStages = ['Intend to Bid', 'Quoted', 'Submitted', 'Awarded'];
      if (validStages.includes(stage)) {
        this.request.stage = stage;
        this.request.lastModified = new Date().toLocaleString();
        // In a real implementation, this would make an API call to update the backend
      }
    }
  }

  addParticipant(participant) {
    if (!this.participants.includes(participant)) {
      this.participants.push(participant);
      this.request.lastModified = new Date().toLocaleString();
      // In a real implementation, this would make an API call to update the backend
    }
  }
}

const worklistStore = new WorklistStore();

export default worklistStore;